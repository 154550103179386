import { Button, Grid, Link, Stack, Typography } from "@mui/material";
import React from "react";
import "./components_bharath.scss";
import HomeBg from "../../../assets/HP/CoverPage.png";
import HomeBgPhone from "../../../assets/HP/CoverPhone.png";

// import HomeBg from "../../../assets/Alt_main.png"
// import HomeBg from "../../../assets/AltMain2.jpg"

export default function NavHomeSearch() {
  const windowWidth = window.innerWidth;
  return (
    <div style={{ height: "90vh", marginTop: "5vh" }}>
      {windowWidth <= 600 ? (
        <img
          src={HomeBgPhone}
          style={{ width: "100%", marginTop: "2rem" }}
          className="homeBg"
          alt="backgroundimage"
        />
      ) : (
        <img
          src={HomeBg}
          style={{ width: "100%", marginTop: "2rem" }}
          className="homeBg"
          alt="backgroundimage"
        />
      )}

      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className="navHomeContainer"
      >
        {/* home section body */}
        <Grid item className="navHomeBodyContainer">
          <Typography variant="h1" className="bodyTitle">
            The law is reason, free from passion
          </Typography>
          <Typography className="bodyDescr" variant="body1">
            The law is reason, free from passion – At our firm, we bring clarity
            and rationality to every case, ensuring that justice is served
            impartially and effectively.
          </Typography>
          <a href="/contactus" target="_blank">
            <div
              className="success-facts"
              style={{
                width: "10rem",
                height: "3.5rem",
                backgroundColor: "#D1B06B",
              }}
            >
              <span
                style={{
                  color: "#1F2732",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <span>
                  <p>Get in touch</p>
                </span>
              </span>
            </div>
          </a>
        </Grid>

        {/* buy and search*/}
      </Grid>
    </div>
  );
}

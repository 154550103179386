import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useRef } from "react";
import "../../homePage/components/components_bharath.scss";
import Myswipercard from "../../homePage/components/cards/swiperSlide";
import Underline from "../../../assets/HP/icons/underline.png";
import Right_arrow from "../../../assets/right-arrow.svg";

import Business from "../../../assets/HP/icons/Suitcase.png";
import Construction from "../../../assets/HP/icons/Building.png";
import Accident from "../../../assets/HP/icons/Truck.png";

const card_details_array = [
  {
    id: 1,
    img: Business,
    loc: "Offers comprehensive legal services to help clients navigate a fast-evolving business environment. With experience across sectors like insurance, real estate, and technology, we advise on mergers, acquisitions, corporate governance, and regulatory compliance.",
    descr: "General Corporate practice",
  },
  {
    id: 2,
    img: Construction,
    loc: "We handle multi-billion-dollar deals and complex control acquisitions for PE funds. We provide seamless, multidisciplinary support across antitrust, employment, IP, and tax to ensure comprehensive transaction solutions. Known for our innovative approach, we excel in navigating challenging private equity deals.",
    descr: "Private Equity",
  },
  {
    id: 3,
    img: Accident,
    loc: "Our Banking & Finance team advises leading financial institutions on complex regulatory challenges, lending, and distressed debt matters. We provide expert guidance on cross-border transactions, combining deep industry knowledge with integrated services across M&A, dispute resolution, and tax.",
    descr: "Banking & Finance",
  },
  {
    id: 4,
    img: Business,
    loc: "Our team advises creditors, resolution professionals, and acquirers, leveraging expertise across Banking & Finance, M&A, Tax, and Dispute Resolution to deliver holistic solutions. We represent clients before the National Company Law Tribunal and provide strategic guidance on cross-border and domestic insolvency matters.",
    descr: "Insolvency & Bankruptcy",
  },
  {
    id: 5,
    img: Construction,
    loc: "We handle cartel investigations, merger filings, and abuse of dominance cases before the Competition Commission of India (CCI) and represent clients in appeals to the National Company Law Appellate Tribunal (NCLAT) and the Supreme Court. Additionally, we offer compliance support, conduct audits.",
    descr: "Competition Law",
  },
  {
    id: 6,
    img: Accident,
    loc: "We focus on dispute avoidance, strategic litigation and enforcement. With expertise in white-collar crime, internal investigations, and compliance, we guide businesses through regulatory challenges, ensuring adherence to stringent anti-corruption laws such as FCPA and UKBA, while mitigating disruption to their operations.",
    descr: "Dispute resolution",
  },
  {
    id: 7,
    img: Accident,
    loc: "Our Capital Markets practice advises clients on IPOs, listings, and capital-raising, covering equity, debt offerings, and regulatory compliance. We help issuers, banks, and financial institutions navigate complex transactions and market expansions.",
    descr: "Capital Markey",
  },
  {
    id: 8,
    img: Accident,
    loc: "Our Intellectual Property practice covers patents, trademarks, copyrights, and designs, offering protection from conceptualization to enforcement. We specialize in IP searches and analysis, ensuring businesses safeguard their assets and maximize protection under Indian law.",
    descr: "Intellectual Property",
  },
];

export default function Services() {
  const myref = useRef(null);
  const progref = useRef(null);

  const buttonScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const getScrollPercent = () => {
    return `${
      (myref.current.scrollLeft /
        (myref.current.scrollWidth - myref.current.clientWidth)) *
      100
    }`;
  };
  const scrollProgressFunc = () => {
    progref.current.style.width = `${getScrollPercent()}%`;
  };

  return (
    <div
      style={{ background: "#161D27", paddingBottom: "4rem" }}
      className="topOffers-main"
    >
      <Grid container id="topOffersContainer" justifyContent="center">
        {/* heading */}
        <Grid item xs={12} className="topOffersItem1">
          <Typography variant="h2" className="topOffersTitle">
            Explore
          </Typography>
          <Typography variant="h2" className="topOffersTitle">
            Our Practice Areas
          </Typography>
        </Grid>

        <Grid item xs={12} className="topOffer-underline">
          <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
        </Grid>
      </Grid>
      <Grid
        container
        style={{ display: "flex" }}
        className="manyCardsContainer"
      >
        <Grid item md={4} sx={4}>
          <Myswipercard
            key={card_details_array[0].id}
            cost={card_details_array[0].cost}
            loc={card_details_array[0].loc}
            descr={card_details_array[0].descr}
            imgsrc={card_details_array[0].img}
          />
        </Grid>

        <Grid item md={4} sx={4}>
          <Myswipercard
            key={card_details_array[1].id}
            cost={card_details_array[1].cost}
            loc={card_details_array[1].loc}
            descr={card_details_array[1].descr}
            imgsrc={card_details_array[1].img}
          />
        </Grid>

        <Grid item md={4} sx={4}>
          <Myswipercard
            key={card_details_array[2].id}
            cost={card_details_array[2].cost}
            loc={card_details_array[2].loc}
            descr={card_details_array[2].descr}
            imgsrc={card_details_array[2].img}
          />
        </Grid>

        <Grid item md={4} sx={4}>
          <Myswipercard
            key={card_details_array[3].id}
            cost={card_details_array[3].cost}
            loc={card_details_array[3].loc}
            descr={card_details_array[3].descr}
            imgsrc={card_details_array[3].img}
          />
        </Grid>

        <Grid item md={4} sx={4}>
          <Myswipercard
            key={card_details_array[4].id}
            cost={card_details_array[4].cost}
            loc={card_details_array[4].loc}
            descr={card_details_array[4].descr}
            imgsrc={card_details_array[4].img}
          />
        </Grid>

        <Grid item md={4} sx={4}>
          <Myswipercard
            key={card_details_array[5].id}
            cost={card_details_array[5].cost}
            loc={card_details_array[5].loc}
            descr={card_details_array[5].descr}
            imgsrc={card_details_array[5].img}
          />
        </Grid>

        <Grid item md={4} sx={4}>
          <Myswipercard
            key={card_details_array[6].id}
            cost={card_details_array[6].cost}
            loc={card_details_array[6].loc}
            descr={card_details_array[6].descr}
            imgsrc={card_details_array[6].img}
          />
        </Grid>

        <Grid item md={4} sx={4}>
          <Myswipercard
            key={card_details_array[7].id}
            cost={card_details_array[7].cost}
            loc={card_details_array[7].loc}
            descr={card_details_array[7].descr}
            imgsrc={card_details_array[7].img}
          />
        </Grid>

        {/* <Grid item md={4} sx={4}>
          <Myswipercard
            key={card_details_array[8].id}
            cost={card_details_array[8].cost}
            loc={card_details_array[8].loc}
            descr={card_details_array[8].descr}
            imgsrc={card_details_array[8].img}
          />
        </Grid> */}
      </Grid>
    </div>
  );
}

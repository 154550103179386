import React, { useEffect } from "react";
import "./services.scss";
import GoogleFormSubmit from "./components/FreeService-1";
import TopContainer from "./components/TopContainer";
import Services from "./components/Services";
import Footer from "../../Components/Footer";
import FreeService from "./components/FreeService";

const windowWidth=window.innerWidth;


function OurServices(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="ourServices">
        
        <TopContainer />
        <Services />
        <FreeService />
        <Footer />
        {/* <GoogleFormSubmit /> */}
    </div>

  );
}
export default OurServices;

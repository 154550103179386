import React, { useEffect } from "react";
import "./disclaimer.scss";
import TopContainer from "./components/TopContainer";
import Footer from "../../Components/Footer";
import AllDsiclaimer from "./components/allDisclaimers";


const windowWidth=window.innerWidth;


function Disclaimer(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="disclaimer">
        <TopContainer />
        <AllDsiclaimer />
        <Footer />
    </div>

  );
}
export default Disclaimer;

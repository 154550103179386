import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";

export default function AboutUsCard(props) {
  const screenWidth = window.outerWidth;
  return (
    <div key={props.key} className="cardSlideContainer ">
      <Card className="cardContainer">
        <CardContent className="cardContentContainer">
          <Grid
            container
            direction="column"
            spacing={2}
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="body1" className="cardDescr">
                {props.descr}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                style={{
                  height: screenWidth > 960 ? "12rem" : "100%",
                  
                  textAlign: "left",
                }}
                variant="body1"
                className="cardLoc"
              >
                {props.loc}
              </Typography>
            </Grid>
            {/* <span style={{ height: "1px", backgroundColor: "#3A4553" }}></span> */}
            {/* <Grid item>
              <img src={props.imgsrc} alt="tower" className="cardImg" />
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

import { Grid, Typography } from "@mui/material";
import React from "react";
import "../disclaimer.scss";
import HomeBg from "../../../assets/HP/services/ServicesMain.png";

export default function TopContainer() {
  const windowWidth = window.innerWidth;
  return (
    <div style={{ height: "90vh", marginTop: "5vh" }}>
        <img
          src={HomeBg}
          style={{ width: "100%", marginTop: "2rem" }}
          className="homeBg"
          alt="backgroundimage"
        />

      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className="navHomeContainer"
      >
        {/* home section body */}
        <Grid item className="navHomeBodyContainer">
          <Typography variant="h1" className="bodyTitle" style={{marginTop: "10rem"}}>
            Disclaimer
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

import HomeIcon from "../assets/HP/icons/home.svg";
import SaleIcon from "../assets/HP/icons/aboutUs.svg";
import RentIcon from "../assets/HP/icons/gavel.svg";
import LegalServicesIcon from "../assets/legalservices-icon.svg";
import ProjectsIcon from "../assets/business-outline.svg";
import ContactUsIcon from "../assets/HP/icons/support.svg";

export const NAVIGATION_ITEMS = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/aboutUs' },
    { name: 'Practice Areas', path: '/services' },
    { name: 'Contact Us', path: '/contactus' },
    // { name: 'Projects', path: '/projects'},
];

export const NAVIGATION_ITEMS_DRAWER= [
    { name: 'Home', path: '/' , icon:HomeIcon},
    { name: 'About Us', path: '/aboutUs' ,icon:SaleIcon},
    { name: 'Practice Areas', path: '/services' ,icon:RentIcon},
    { name: 'Contact Us', path: '/contactus' ,icon:ContactUsIcon},
];

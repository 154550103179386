import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./pages/homePage/index";
import AboutUs from "./pages/aboutUs/index";
import Disclaimer from "./pages/disclaimer/index";
import Contactus from "./pages/ContactUs/index";
import OurServices from "./pages/Services";

function App() {
	return (
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/aboutUs" element={<AboutUs />} />
				<Route path="/services" element={<OurServices />} />
				<Route path="/contactus" element={<Contactus />} />
				<Route path="/disclaimer" element={<Disclaimer />} />
				{/* <Route path='/forSaleContent' element={<ForSaleContent/>} />
				<Route path="/forRentContent" element={<ForRentContent />} /> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;

import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useRef } from "react";
import "./components_bharath.scss";
import Myswipercard from "./cards/swiperSlide";
import Right_arrow from "../../../assets/right-arrow.svg";
import Underline from "../../../assets/HP/icons/underline.png";

import Business from "../../../assets/HP/icons/Suitcase.png";
import Construction from "../../../assets/HP/icons/Building.png";
import Accident from "../../../assets/HP/icons/Truck.png";

const card_details_array = [
  {
    id: 1,
    img: Business,
    loc: "Offers comprehensive legal services to help clients navigate a fast-evolving business environment. With experience across sectors like insurance, real estate, and technology, we advise on mergers, acquisitions, corporate governance, and regulatory compliance.",
    descr: "General Corporate practice",
  },
  {
    id: 2,
    img: Construction,
    loc: "We handle multi-billion-dollar deals and complex control acquisitions for PE funds. We provide seamless, multidisciplinary support across antitrust, employment, IP, and tax to ensure comprehensive transaction solutions. Known for our innovative approach, we excel in navigating challenging private equity deals.",
    descr: "Private Equity",
  },
  {
    id: 3,
    img: Accident,
    loc: "Our Banking & Finance team advises leading financial institutions on complex regulatory challenges, lending, and distressed debt matters. We provide expert guidance on cross-border transactions, combining deep industry knowledge with integrated services across M&A, dispute resolution, and tax.",
    descr: "Banking & Finance",
  },
];

export default function TopOffers() {
  const myref = useRef(null);
  const progref = useRef(null);

  const buttonScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const getScrollPercent = () => {
    return `${
      (myref.current.scrollLeft /
        (myref.current.scrollWidth - myref.current.clientWidth)) *
      100
    }`;
  };
  const scrollProgressFunc = () => {
    progref.current.style.width = `${getScrollPercent()}%`;
  };

  return (
    <div style={{ background: "#161D27", paddingBottom: "4rem" }} className="topOffers-main">
      <>
        <Grid
          container
          direction="column"
          id="topOffersContainer"
          justifyContent="center"
        >
          {/* heading */}
          <Grid item xs={12} className="topOffersItem1">
            <Typography variant="h2" className="topOffersTitle">
              Explore
            </Typography>
            <Typography variant="h2" className="topOffersTitle">
              Our Practice Areas
            </Typography>
          </Grid>

          <Grid item xs={12} className="topOffer-underline">
            <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
          </Grid>

          {/* description and button */}
          {/* <Grid item container direction={{md:'row',xs:'column'}} justifyContent={{md:'space-between',xs:'space-between'}} alignItems={{md:'center',xs:'flex-start'}} className='topOffersItem2' >
            <Grid item md={8}>
                <Typography variant='body1' className='topOffersDescr'>
                    Fulfill your career dreams, enjoy all the achievements of the city center and luxury housing to the fullest.
                </Typography>
            </Grid>
        </Grid> */}

          {/* progress bar and left right buttons */}
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            className="topOffersItem3"
          >
            <Grid item className="progressBarContainer">
              <div className="progressBar" ref={progref}></div>
            </Grid>
            <Grid
              item
              container
              className="leftRightBtnContainer"
              spacing={2}
              md={2}
            >
              {/* <Grid item>
             <img alt='left' className='leftBtn' src={Left_arrow}
              onClick={()=>{buttonScroll(myref.current, 25, 100, -10);}} />     
          </Grid> */}
              {/* <Grid item>
                <img
                  alt="right"
                  className="rightBtn"
                  src={Right_arrow}
                  onClick={() => {
                    buttonScroll(myref.current, 25, 100, 10);
                  }}
                />
              </Grid> */}
            </Grid>
          </Grid>

          {/* card carsousel */}
          <Grid item className="topOffersItem4">
            <div
              onScroll={scrollProgressFunc}
              className="manyCardsContainer scroll-div"
              ref={myref}
            >
              {card_details_array.map((obj) => (
                <Myswipercard
                  key={obj.id}
                  cost={obj.cost}
                  loc={obj.loc}
                  descr={obj.descr}
                  imgsrc={obj.img}
                />
              ))}
            </div>
          </Grid>
        </Grid>
        <span className="learn-more">
          <a href="/services" type="button"
            className="success-facts"
            style={{
              width: "18rem",
              height: "3rem",
              backgroundColor: "#D1B06B",
              display: "flex", justifyContent: "center", alignItems: "center"
            }}
            
          >
            <span style={{ color: "#161D27", fontWeight: 600 }}>
                <p>Learn more</p>
            </span>
          </a>
          </span>
      </>
    </div>
  );
}

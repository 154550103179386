import React, { useState } from "react";
import "./meetOurteam.scss"; // Make sure to include your CSS styles
import { Grid, Typography } from "@mui/material";
import Underline from "../../../assets/HP/icons/underline.png";
import Aditya from "../../../assets/Aditya.jpg";
import Hitesh from "../../../assets/hitesh.jpeg";
import Prashanth from "../../../assets/prashant.jpg";
import Ankit from "../../../assets/ankit.jpg";
import Akash from "../../../assets/akash.jpg";
import Official from "../../../assets/official.png";

const membersData = [
  {
    id: "m1",
    imgSmall: Aditya,
    imgLarge:
      "https://images.unsplash.com/photo-1628157588553-5eeea00af15c?q=80&w=400&auto=format&fit=crop",
    name: "Aditya Kolpuke",
    title: "Founder and Managing Partner",
    bio: `Specializes in Mergers & Acquisitions, Private Equity and Venture Capital, General Corporate Advisory, and Private Client matters, with a particular emphasis on cross-border and multi-jurisdictional transactions. Throughout his legal career, he has provided guidance on a range of foreign and domestic acquisitions, investments, and joint ventures, including transactions in the public market. Aditya delivers practical advice to multinational corporations, the banking sector, private equity funds, investment banks, and businesses involved in complex deals.`,
  },
  {
    id: "m10",
    imgSmall:
      Hitesh,
    imgLarge:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=400&auto=format&fit=crop",
    name: "Hitesh Kolpuke",
    title: "Partner",
    bio: `Partner at the firm, primarily focused on automations and optimizing processes to enhance operational efficiency. With a strong commitment to executing projects effectively, he leverages technology to streamline workflows and improve overall productivity. He has a keen eye for identifying areas where automation can reduce manual effort and minimize errors, enabling the team to deliver high-quality results more quickly.`,
  },
  {
    id: "m2",
    imgSmall: Official,
    imgLarge:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=400&auto=format&fit=crop",
    name: "Siddhesh Sawant",
    title: "Partner",
    bio: `Serves as a Partner in the Banking and Finance practice group at our Mumbai office. He specializes in various aspects of financing, including structured finance and project financing. With a deep understanding of regulatory compliance, he collaborates closely with clients to address their unique needs, ensuring tailored strategies that promote business success.`,
  },
  {
    id: "m8",
    imgSmall:
      Akash,
    imgLarge:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=400&auto=format&fit=crop",
    name: "Akash More",
    title: "Chief Financials officer",
    bio: `Manages all financial-related processes within the firm. He is responsible for overseeing budgeting, forecasting, and financial reporting to ensure accuracy and compliance with regulatory standards. Additionally, Akash plays a key role in analyzing financial data to support strategic decision-making, helping the firm optimize its financial performance.`,
  },
  {
    id: "m9",
    imgSmall:
      Ankit,
    imgLarge:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=400&auto=format&fit=crop",
    name: "Ankit Mhatre",
    title: "Office Managing partner",
    bio: `Responsible for managing all client relations and overseeing every aspect of client engagement within the firm. He excels at building and maintaining strong relationships with clients, ensuring their needs are met and expectations exceeded. He is adept at coordinating communication between clients and various departments, facilitating seamless interactions and timely responses.`,
  },
  {
    id: "m7",
    imgSmall:
      Prashanth,
    imgLarge:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=400&auto=format&fit=crop",
    name: "Prashant Bagle",
    title: "Legal & CS",
    bio: `Legal Associate and Company Secretary at the firm, bringing a dual expertise that enhances his contributions to both legal and corporate governance matters. He specializes in compliance and regulatory affairs, ensuring that clients adhere to relevant laws and industry standards. Prashant is adept at drafting legal documents, conducting due diligence, and providing sound legal advice on corporate transactions.`,
  },
  {
    id: "m3",
    imgSmall:
      Official,
    imgLarge:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=400&auto=format&fit=crop",
    name: "Harshada Tamboli",
    title: "Partner",
    bio: `Leads the firm’s Land-related matters, as well as its Banking & Finance and Non-Banking Financial Company (NBFC) practices. With specialized expertise in these areas, she provides comprehensive legal guidance on real estate transactions, regulatory compliance, and financial structuring.`,
  },
  {
    id: "m4",
    imgSmall:
      Official,
    imgLarge:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=400&auto=format&fit=crop",
    name: "Purva Kshatriya",
    title: "Legal associate",
    bio: `Advocate who graduated from the ILAS Institute, bringing a wealth of practical experience to the firm. With a strong understanding of various legal frameworks, she is skilled in drafting legal documents and managing the processes involved in court proceedings. She actively engages in consultations to understand their unique needs, tailoring her approach to achieve optimal outcomes.`,
  },
  {
    id: "m5",
    imgSmall:
      Official,
    imgLarge:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=400&auto=format&fit=crop",
    name: "Sameer Phulore",
    title: "Business development manager",
    bio: `Serves as the Business Development Manager at the firm, leveraging his extensive network in the banking and NBFC sectors, as well as connections with numerous individuals. He is skilled in identifying new business opportunities and fostering strategic partnerships that drive growth.`,
  },
  {
    id: "m6",
    imgSmall:
      Official,
    imgLarge:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=400&auto=format&fit=crop",
    name: "Nilesh Chalmale",
    title: "Jr Legal associate",
    bio: `Manages all aspects of regulatory processes and court proceedings within the firm. He brings extensive experience in navigating complex legal frameworks, ensuring compliance with relevant laws and regulations. He is known for his strong advocacy skills, effectively representing clients in Various Legal Matters.`,
  }
];

const Tile = ({ index, member, isActive, onClick }) => (
  <label
    htmlFor={member.id}
    className={`tile ${isActive ? "active" : ""}`}
    onClick={() => onClick(index)}
  >
    <img src={member.imgSmall} alt={member.name} style={{height: "7rem"}} />
  </label>
);

const Card = ({ member, isChecked }) => (
  <div
    className={`card-wrapper ${isChecked ? "active" : ""}`}
    style={{ display: isChecked ? "flex" : "none" }}
  >
    <input
      type="radio"
      id={member.id}
      name="members"
      checked={isChecked}
      readOnly
    />
    <div className="card">
      {/* <img src={member.imgLarge} className="avatar" alt={member.name} /> */}
      <div className="info">
        <div className="name">{member.name}</div>
        <div className="title">{member.title}</div>
        <div className="bio">
          {member.bio.split("\n").map((paragraph, index) => (
            <p style={{fontSize: '1.2rem'}} key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  </div>
);

function TileContainer() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <span>
      <span
        style={{
          marginLeft: "2rem",
          marginTop: "4rem",
          marginBottom: "2rem",
          display: "flex",
          flexDirection: "column",
          marginBottom: '4rem'
        }}
      >
        <Grid item xs={12} className="topOffersItem1">
          <Typography variant="h2" className="topOffersTitle">
            Our Team
          </Typography>
        </Grid>
        <Grid item xs={12} className="topOffer-underline">
          <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
        </Grid>
      </span>

      <div className="wrapper" style={{marginBottom: '6rem'}}>
        <div className="team-wrapper">
          <div className="members">
            {membersData.map((member, index) => (
              <Tile
                key={member.id}
                index={index}
                member={member}
                isActive={index === activeIndex}
                onClick={handleClick}
              />
            ))}
          </div>
          <div className="divider"></div>
          <div className="cards">
            {membersData.map((member, index) => (
              <Card
                key={member.id}
                member={member}
                isChecked={index === activeIndex}
              />
            ))}
          </div>
        </div>
      </div>
      
    </span>
  );
}

export default TileContainer;

import React, { useEffect } from "react";
import "./aboutUs.scss";
import TopSection from "./components/TopSection";
import AboutUs from "./components/AboutUsSection";
import Footer from "../../Components/Footer";
import MeetOurTeam from "./components/MeetOurTeam";

const windowWidth=window.innerWidth;


function AboutUsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="aboutUsPage">
      <TopSection />
      <AboutUs />
      <MeetOurTeam />
      <Footer />
    </div>

  );
}
export default AboutUsPage;

import React from "react";
import { Grid } from "@mui/material";
import "../contactus.scss";
import Underline from "../../../assets/HP/icons/underlineFull.png";
import Hall from "../../../assets/HP/Hall.png";
import FaqsCard from "./cards/questions";

const faqs = [
  {
    id: 1,
    question: "Why choose a law firm over an individual advocate?",
    answer:
      "A law firm offers several advantages: multiple attorneys bring a broad range of expertise and resources, ensuring more comprehensive service. Firms are also dedicated and consistently available, providing focused attention to your case.",
  },
  {
    id: 2,
    question: "Will my case information be kept confidential?",
    answer:
      "Yes, confidentiality is a top priority. We adhere to strict privacy standards and ethical guidelines mandated by Indian courts.",
  },
  {
    id: 3,
    question: "What jurisdictions do you operate in?",
    answer:
      "We are licensed to practice throughout India with offices in Mumbai, Navi Mumbai, and Pune. If you are out of state or abroad, we can assist via online consultations, phone, email, or Skype, and can arrange for on-site support if needed.",
  },
  {
    id: 4,
    question: "How can I contact you online?",
    answer:
      "You can reach us through email, phone, or Skype. Our team will respond within 24 hours to any questions or concerns.",
  },
  {
    id: 5,
    question: "Do you handle litigation?",
    answer:
      "Yes, our dedicated litigation department handles cases in various areas including medical law, family law, media law, intellectual property, and commercial law within Indian courts.",
  },
  {
    id: 6,
    question: "What will the legal services cost?",
    answer: (
      <>
        <b>We offer various fee arrangements:</b>
        <ul style={{marginLeft: "1.5rem"}}>
          <li>
            <b>Hourly Fee:</b> Charges based on the time spent, detailed in an
            itemized bill.
          </li>
          <li>
            <b>Flat Fee:</b> A set amount for agreed services, with no further
            billing.
          </li>
          <li>
            <b> Contingency Fee:</b> Fees based on the recovery amount; no fee
            if there is no recovery. Not all cases are eligible for this.
          </li>
        </ul>
        Additional fees may include pre-payments or minimum charges. We strive
        for reasonable fees and encourage open discussion about costs with your
        lawyer.
      </>
    ),
  },
  {
    id: 5,
    question: "What types of services do you offer?",
    answer:
      "We handle a broad range of litigation services including Arbitration, Competition Laws, Contracts, Consumer Protection, Corporate Advisory, Taxation (Direct and GST), Intellectual Property, International Trade, Insolvency, Real Estate, Environmental Law, and more.",
  },
];

function Faqs(props) {
  return (
    <div className="howitworksContainer faqs-container">
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          className="howitworks-text"
          alignItems="center"
          justifyContent="center"
        >
          FAQs
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center", margin: "1rem" }}
        >
          <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
        </Grid>
        <Grid container className="howitworksCards" spacing={1}>
          {faqs.map((faq) => (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FaqsCard question={faq.question} answer={faq.answer} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: '4rem',
            paddingBottom: "6rem",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3767.7561448938777!2d73.0035833!3d19.2058504!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bec588c9a20d%3A0xf48671c0c3dc89d1!2sKishor%20Angan!5e0!3m2!1sen!2sin!4v1728240523642!5m2!1sen!2sin"
            width="80%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            class="map"
          ></iframe>
        </span>
      </Grid>
     
    </div>
  );
}
export default Faqs;

import { Grid, Typography } from "@mui/material";
import React from "react";
import "./TopSection.scss";
import Topimg from "../../../assets/HP/services/Certificate.png";
import Underline from "../../../assets/HP/icons/underline.png";

export default function FreeService() {
  const screenWidth = window.outerWidth;
  return (
    <div className="freeServiceContainer" style={{ marginBottom: "10rem" }}>
      {/* title featured listing of the week */}
      {/* <Typography variant='h1' className='featuredListingTitle'>
     About Us
    </Typography> */}

      {/* container for left n right containers */}
      <Grid
        container
        className="featuredListBox"
        direction={{ md: "row", xs: "column" }}
        justifyContent={{ md: "center", xs: "center" }}
        alignItems={{ md: "center", xs: "center" }}
      >
        {/* right container */}
        <Grid
          item
          className="rightContainer"
          container
          direction={{ md: "column", xs: "column" }}
          rowSpacing={{ md: 2, xs: 4 }}
          md={6}
          justifyContent={{ md: "center", xs: "center" }}
          alignItems={{ md: "flex-start", xs: "center" }}
        >
          <Grid item>
            <Typography variant="h4" className="rightContainerTitle">
            Our professional law team is always ready to give you optimal solutions/ best solutions/costeffective solutions!
            </Typography>
          </Grid>

          <Grid item>
            <img style={{ width: "4rem" }} src={Underline} />
          </Grid>

          <Grid item>
            <Typography variant="body1" className="rightContainerDescr">
              We handle a broad range of litigation services including
              arbitration, aompetition laws, contracts, consumer protection,
              corporate advisory, taxation (direct and GST), intellectual
              property, international trade, insolvency, real estate,
              environmental law, and more.
            </Typography>
          </Grid>

          {/* <Grid item container rowSpacing={3.3} columnSpacing={2.2} >
              {featuredList.map(obj=>(
                <Grid item md={4} xs={6}>
                <Typography className='rightContainerFeatures' variant='h6'>{obj.feature}</Typography>
                <Typography className='rightContainerFeatureValues' variant='p'>{obj.value}</Typography>
              </Grid>
              ))}
          </Grid> */}

          {/* <Grid item >
            <button id='rightContainerButton' >
              YES! I WANT BOOK “OFFICE PACKAGE”
            </button>
          </Grid> */}
          <span
            style={{
              marginTop: "2rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: screenWidth > 960 ? "left" : "center",
              width: "80%",
            }}
          >
            <div>
              <div
                className="success-facts"
                style={{
                  width: "10rem",
                  height: "4rem",
                  backgroundColor: "#D1B06B",
                }}
              >
                <span
                  className="content"
                  style={{ color: "#1F2732", padding: 0 }}
                >
                  <a
                    href="/contactus"
                    target="_blank"
                    type="button"
                    style={{ color: "#FFFFFF" }}
                  >
                    <p>Contact us</p>
                  </a>
                </span>
              </div>
            </div>
          </span>
        </Grid>
        {/* left container */}
        <Grid item className="leftContainer" md={6}>
          <img className="topImg" src={Topimg} alt="img" />
          {/* <img className='bottomImg' src={BottomImg} alt='img'/> */}
        </Grid>
      </Grid>
    </div>
  );
}

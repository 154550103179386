import React, { useEffect } from "react";
import "./contactus.scss";
import TopContainer from "./components/TopContainer";
import GoogleFormSubmit from "./components/GoogleForm";
import Footer from "../../Components/Footer";
import Faqs from "./components/faq";
import { Work } from "@mui/icons-material";

const windowWidth=window.innerWidth;


function ContactUs(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="contactUs">
        
        <TopContainer />
        <GoogleFormSubmit />
        <Faqs />
        <Footer />
    </div>

  );
}
export default ContactUs;

import React from "react";
import "./footer.scss";
import LinkedIn from "../assets/LinkedIn.svg";
import Facebook from "../assets/Facebook.svg";
import Instagram from "../assets/Instagram.svg";
import { Grid, Stack, Typography } from "@mui/material";
import WaLogo from "../assets/HP/walogo.png";
import Hall from "../assets/HP/Hall.png";

function Footer() {
  return (
    <>
      <div
        style={{
          height: "20vh",
          overflow: "hidden",
          display: "flex",
          position: "relative",
          paddingBottom: 100,
          backgroundImage: `url(${Hall})`,
        }}
      >
        {/* <img src={HomeBg} style={{width:"100%", position: "fixed", height: "100vh"}} className='homeBg' alt='backgroundimage'/> */}
      </div>

      <Grid
        id="footerContainer"
        container
        direction={{ md: "row", xs: "column" }}
        align-items={{ md: "center", xs: "center" }}
        rowSpacing={{ md: 0, xs: 8 }}
        justifyContent={{ md: "space-evenly", xs: "space-evenly" }}
        style={{ marginTop: "10vh" }}
      >
        <Grid></Grid>
        {/* <Grid
        item
        container
        direction={{ md: "column", xs: "column" }}
        className="firstBox"
        alignItems={{ md: "flex-start", xs: "center" }}
        justifyContent={{ md: "center", xs: "center" }}
        md={12}
        xs={12}
        rowSpacing={{ md: 3, xs: 3 }}
      >
        <Grid item className="firstBoxItem1">
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <img style={{ width: "20vw" }} src={leftUnderline} />
        
            <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <h1 className="footertitle" style={{marginBottom: '1rem'}}>HP AND ASSOCIATES</h1>
            <h4 className="footertitle">ADVOCATES AND SOLICITORS</h4>
            </span>
            <img style={{ width: "20vw" }} src={rightUnderline} />
            
          </Stack>
        </Grid>
      </Grid> */}

        <Grid
          item
          container
          direction={{ md: "row", xs: "row" }}
          justifyContent={{ md: "space-around", xs: "space-between" }}
          alignItems={{ md: "flex-start", xs: "flex-start" }}
          md={4}
          xs={12}
          className="secondAndThirdContainer"
        >
          <Grid item className="secondBox">
            <span
              style={{
                height: ".1px",
                backgroundColor: "#3A4553",
                display: "flex",
                width: "8rem",
              }}
            ></span>
            <Typography className="secondBoxTitle" variant="h5">
              Quick Links
            </Typography>
            <span
              style={{
                height: ".1px",
                backgroundColor: "#3A4553",
                display: "flex",
                width: "8rem",
              }}
            ></span>
            <Stack
              direction="column"
              alignItems="flex-start"
              style={{ marginTop: "1.2rem" }}
            >
              <a className="secondBoxLinks" href="/">
                Home
              </a>
              <a className="secondBoxLinks" href="/aboutUs">
                About
              </a>
              <a className="secondBoxLinks" href="/services">
                Practice Areas
              </a>
              <a className="secondBoxLinks" href="/contactus">
                Contact Us
              </a>
              <a className="secondBoxLinks" href="/disclaimer">
                Disclaimer
              </a>
            </Stack>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction={{ md: "row", xs: "row" }}
          justifyContent={{ md: "space-around", xs: "space-between" }}
          alignItems={{ md: "flex-start", xs: "flex-start" }}
          md={4}
          xs={12}
          className="secondAndThirdContainer"
        >
          <Grid item className="secondBox">
            <span
              style={{
                height: ".1px",
                backgroundColor: "#3A4553",
                display: "flex",
                width: "8rem",
              }}
            ></span>
            <Typography className="secondBoxTitle" variant="h5">
              Practice Areas
            </Typography>
            <span
              style={{
                height: ".1px",
                backgroundColor: "#3A4553",
                display: "flex",
                width: "8rem",
              }}
            ></span>
            <Stack
              direction="column"
              alignItems="flex-start"
              style={{ marginTop: "1.2rem" }}
            >
              <a className="secondBoxLinks" href="/services">
                General Corporate practice
              </a>
              <a className="secondBoxLinks" href="/services">
                Private Equity
              </a>
              <a className="secondBoxLinks" href="/services">
                Banking & Finance
              </a>
              <a className="secondBoxLinks" href="/services">
                Insolvency & Bankruptcy
              </a>
              <a className="secondBoxLinks" href="/services">
                Competition Law
              </a>
              <a className="secondBoxLinks" href="/services">
                Dispute resolution
              </a>
              <a className="secondBoxLinks" href="/services">
                Capital Markey
              </a>
              <a className="secondBoxLinks" href="/services">
                Intellectual Property
              </a>
            </Stack>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction={{ md: "row", xs: "row" }}
          justifyContent={{ md: "space-around", xs: "space-between" }}
          alignItems={{ md: "flex-start", xs: "flex-start" }}
          md={4}
          xs={12}
          className="secondAndThirdContainer"
        >
          <Grid item className="secondBox">
            <span
              style={{
                height: ".1px",
                backgroundColor: "#3A4553",
                display: "flex",
                width: "8rem",
              }}
            ></span>
            <Typography className="secondBoxTitle" variant="h5">
              You can reach out through the following contact details:
            </Typography>
            <span
              style={{
                height: ".1px",
                backgroundColor: "#3A4553",
                display: "flex",
                width: "8rem",
              }}
            ></span>
            <Stack
              direction="column"
              alignItems="flex-start"
              style={{ marginTop: "1.2rem" }}
            >
              <a
                className="secondBoxLinks"
                href="https://maps.app.goo.gl/JLuuSUm1XAJxhKcQ8"
                style={{ marginBottom: "1.2rem" }}
              >
                <b>Thane:</b>{" "}
                <span style={{ textDecoration: "underline" }}>
                  G/8,G/9 & G/10 Kishor Aangan, behind Dominos, Parsik Nagar,
                  Kalwa, Thane, Maharashtra 400605.
                </span>
              </a>

              <a
                className="secondBoxLinks"
                href="https://maps.app.goo.gl/wXgyPBeVC2RmB9Hg7"
                style={{ marginBottom: "1.2rem" }}
              >
                <b>Navi Mumbai:</b>{" "}
                <span style={{ textDecoration: "underline" }}>
                  Block No 305, Thacker Tower, Sector 17, Vashi, Navi Mumbai,
                  Maharashtra 400703.
                </span>
              </a>

              <p className="secondBoxLinks">
                <b> Phone:</b> 9867280864 / 9987880864
              </p>
              <a
                className="secondBoxLinks"
                href="https://mail.google.com/mail/?view=cm&fs=1&to=legal@adihitcapella.com"
                target="_blank"
              >
                <b>Email:</b>legal@adihitcapella.com
              </a>
              <p className="secondBoxLinks">
                <b>Office hours: </b>
                Monday to Saturday: 10:00 AM – 7:30 PM
              </p>
              <p className="secondBoxLinks">Sunday: Closed</p>
              <p className="secondBoxLinks">
                <i>For inquiries or to schedule a consultation, you can call or
                send an email. The team at HP and Associates is available to
                assist with any legal questions or concerns you may have.</i>
              </p>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction={{ md: "row", xs: "row" }}
          justifyContent={{ md: "space-around", xs: "space-between" }}
          alignItems={{ md: "flex-start", xs: "flex-start" }}
          md={12}
          xs={12}
          style={{ color: "#FFFFFF" }}
        >
          <Grid item md={12} xs={12}>
            <span
              className="footer-divider"
              style={{
                height: ".1px",
                backgroundColor: "#3A4553",
                display: "flex",
                width: "100%",
                marginBottom: "1rem",
              }}
            ></span>
          </Grid>
          <Grid item md={2} xs={12} style={{ marginBottom: "1rem" }}>
            Copyright @ 2024
          </Grid>
          <Grid item md={4} xs={12} style={{ marginBottom: "2rem" }}>
            | Designed by{" "}
            <a style={{ color: "#D1B06B" }}>TechYatra Business Solutions</a>
          </Grid>
          <Grid item md={2} xs={0}></Grid>
          <Grid item md={3} xs={12}>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <p style={{ color: "#D1B06B" }}>Follow: </p>

              <a
                href="https://www.linkedin.com/company/adihit-capella/people/"
                target="_blank"
              >
                <img
                  className="socialIcons"
                  src={LinkedIn}
                  style={{ color: "#FFFFFF" }}
                  alt="ln"
                />
              </a>
              <img className="socialIcons" src={Instagram} alt="Ig" />
              <img className="socialIcons" src={Facebook} alt="fb" />
            </span>
          </Grid>
          <Grid item md={1} xs={0}></Grid>
          {/* <Grid item xs={12}>
          <span
            style={{
              height: ".1px",
              backgroundColor: "#3A4553",
              display: "flex",
              width: "100%",
              marginBottom: "4rem",
              marginTop: "1rem",
            }}
          ></span>
        </Grid> */}
        </Grid>
        <div
          className="waicon"
          style={{
            width: "4rem",
            height: "4rem",
            position: "fixed",
            bottom: 32,
            right: 58,
          }}
        >
          <a aria-label="Chat on WhatsApp" href="https://wa.me/919867280864">
            <img
              src={WaLogo}
              style={{
                width: "10rem",
                position: "fixed",
                bottom: 10,
                right: 10,
              }}
            />
          </a>
        </div>
      </Grid>
    </>
  );
}

export default Footer;
